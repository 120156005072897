<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 0.2), rgba(255, 255, 255, 0.2)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="250"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-5"> {{ user.nombres }} </v-list-item-title>
          <v-list-item-subtitle class="white--text">{{ user.idusuario }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!--<template v-slot:append>
      <base-item
        :item="{
          title: 'Innovahtec 2.0',
          icon: 'mdi-package-up',
          to: 'https://innovahtecgroup.com/',
        }"
      />
    </template>-->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "mdi-license",
        title: "Lista Licencia",
        to: "/dashboard/licencia/list",
      },
      {
        icon: "mdi-cube-scan",
        title: "Lista Token",
        to: "/dashboard/tokens/list",
      },
     
      {
        icon: "mdi-access-point-check",
        title: "Solicitud de Accesos",
        to: "/dashboard/solicitud-accesos/list",
      },
      {
        icon: "mdi-format-list-bulleted",
        title: "Preferencias",
        to: "/dashboard/preferencias/list",
      },
     
      
    ],
    user: {}
  }),

  computed: {
    //...mapState(["barColor", "barImage"]),
    ...mapState('va', ['barColor', 'barImage']),
    ...mapState(['authU']),
    drawer: {
      get() {
        return this.$store.state.va.drawer;
      },
      set(val) {
        this.$store.commit("va/SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "IKUBE LICENCIAS",
      };
    },
  },

  async created(){
    console.log('drwaer')
    this.user = this.$store.getters.getUser
    await this.listarMenus();
    console.log( 'users', this.user)
  
  },

  methods: {
    async logindemo(){

      await this.$store.dispatch("logout");
      return false;
      
      const user = {
          data: {
            type: "token",
            attributes: {
              email: 'email',
              password: 'password'
            }
          }
        }

        const requestOptions = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
          }
        }

        console.log(user, requestOptions, 'login')
        //return false

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (e) {
        console.log(e)
        //await this.$store.dispatch("alerts/error", "Invalid credentials!");
        //this.setApiValidation(e.response.data.errors);
      }
    },
    async listarMenus() {
      try {
        const response = (
          await this.$xxhttp.request(
            `menus`
          )
        ).data;
        this.items = response.status ? response.data : [];
        console.log(this.items)
      } catch (e) {
        console.log(e);
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
